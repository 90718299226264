/* eslint-disable theme-colors/no-literal-colors */

const trializeTheme = {
  borderRadius: 8,
  colors: {
    text: {
      label: '#A5AAC1',
      help: '#27346D',
    },
    primary: {
      base: '#2B63FF',
      dark1: '#214DCC',
      dark2: '#1A37A1',
      light1: '#6780FF',
      light2: '#A4ADFF',
      light3: '#D0D7FF',
      light4: '#E8EBFF',
      light5: '#F3F5FF',
    },
    grayscale: {
      base: '#A5AAC1',
      dark1: '#4F546C',
      dark2: '#0F172A',
      light1: '#A5AAC1',
      light2: 'var(--theme-palette-divider, #FAFBFF)',
      light3: '#FAFBFF',
      light4: '#FAFBFF',
      light5: '#FFFFFF',
    },
    error: {
      base: '#EB5954',
      dark1: '#B94442',
      dark2: '#871F31',
      light1: '#F3A3A0',
      light2: '#FCE6E5',
    },
    success: {
      base: '#3DBC91',
      dark1: '#2F946E',
      dark2: '#216C4B',
      light1: '#8EDDB0',
      light2: '#E0F4E6',
    },
    warning: {
      base: '#FDAA4A',
      dark1: '#C2843A',
      dark2: '#8A5E29',
      light1: '#FEC88A',
      light2: '#FFEDD5',
    },
    info: {
      base: '#65BBFC',
      dark1: '#4D93C2',
      dark2: '#346A91',
      light1: '#A2D3FC',
      light2: '#E6F4FE',
    },
  },
  opacity: {
    light: '10%',
    mediumLight: '35%',
    mediumHeavy: '60%',
    heavy: '80%',
  },
  typography: {
    families: {
      sansSerif: `'Averta CY', 'Inter', Helvetica, Arial`,
      serif: `Georgia, 'Times New Roman', Times, serif`,
      monospace: `'Fira Code', 'Courier New', monospace`,
    },
    weights: {
      light: 300,
      normal: 400,
      medium: 500,
      bold: 600,
    },
    sizes: {
      xxs: 9,
      xs: 10,
      s: 12,
      m: 14,
      l: 16,
      xl: 21,
      xxl: 28,
    },
  },
  zIndex: {
    aboveDashboardCharts: 10,
    dropdown: 11,
    max: 3000,
  },
  transitionTiming: 0.3,
  gridUnit: 4,
  brandIconMaxWidth: 37,
};

export default trializeTheme;
